<template>
  <div v-loading="isLoading" class="flex flex-col overflow-hidden h-screen font-medium">
    <the-distribution-modal :is-shared-view="true"></the-distribution-modal>
    <!-- Main Nav Bar -->
    <div class="text-sm font-medium bg-gray-800 text-white py-5 flex items-center">
      <div class="px-4">
        <div class="flex items-center space-x-5 sm:space-x-10">
          <span class="font-semibold text-xl">NorthArc</span>
        </div>
      </div>
      <span class="text-white font-semibold text-xl ml-auto mr-8">Deling af workspace <span class="border-b">{{ workspaceName }}</span></span>
    </div>
    <!-- Secondary Nav Bar -->
    <div class="text-sm font-medium text-black py-5 flex items-center">
    <div class="px-8">
      <div class="flex items-center space-x-5 sm:space-x-10">
        <span v-if="drivingListInfo" class="dark-link" @click="downloadDrivingLists" style="cursor: pointer;">Download kørelister</span>
        <span class="dark-link" @click="setDistributionModalOpen(true)" style="cursor: pointer;">Optælling</span>
      </div>
    </div>
  </div>
    <keep-alive>
      <div class="flex flex-col double-screen-h">
        <!-- Map Container -->
        <div ref="mapContainer" class="flex map-container-height overflow-hidden">
          <!-- Planning Area Section -->
            <div class="w-96 flex-shrink-0 bg-white flex flex-col">
              <!-- Planning Area component -->
              <planning-area
                :vehicle-options="vehicleOptions"
                :frequency-options="frequencyOptions"
                :is-shared-view="true"
                @filter="onFilter"
                style="padding-bottom: 81px; border: 1px solid #ccc;"
              ></planning-area>
            </div>
          <!-- Map Section -->
          <div class="flex flex-col flex-1 map-container-height">
            <!-- Leaflet Route Map component -->
            <the-leaflet-route-map
              ref="map"
              :partition-summary="partitionSummary"
              :popup-data-fetcher="getPlannedInstanceDetails"
              :info-modal-handler="onInfoModalClick"
              :is-shared-view="false"
              @open-grid="openGrid"
            ></the-leaflet-route-map>
            <!-- Partition Summary component -->
            <the-partition-summary
              :partition-summary="partitionSummary"
              :loading="plannedInstancesLoading"
            ></the-partition-summary>
          </div>
          <!-- Info Modal component -->
          <the-planning-info-modal
            v-model="showInfoModal"
            :is-shared-view="true"
            @closed="onInfoModalClose"
          ></the-planning-info-modal>
        </div>
        <!-- Task Table Section -->
        <div ref="gridContainer" class="relative h-screen overflow-y-auto slim-scrollbar">
          <div class="flex flex-col px-4 pt-4 sm:px-6 lg:px-8 bg-gray-100">
            <div class="flex justify-between">
              <h2 class="text-2xl font-extrabold text-gray-900 mb-4">Visning af {{taskCount}} valgte opgaver m. {{totalQuantity}} spande</h2>
              <div class="flex space-x-8">
                <base-button color="indigo" size="xl" @click="closeTaskTable">
                  <span>Tilbage til kort</span>
                  <span class="bg-gray-50 text-indigo-900 rounded-md ml-4 text-xs px-2.5 py-1">ESC</span>
                </base-button>
              </div>
            </div>
            <!-- Task Table component -->
            <task-table
              :selected-tasks="taskIdList"
              :is-shared-view="true"
              :hide-scroll-helpers="!isGridActive"
              :is-selection-mode="true"
              @has-updated="hasUpdated = true"
            ></task-table>
          </div>
        </div>
      </div>
    </keep-alive>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  SET_MOST_IRREGULAR_FREQUENCY,
  SET_PLANNED_INSTANCE_LOADING,
  SET_PARTITION_SUMMARY,
  SET_DISTRIBUTION_MODAL_OPEN,
} from '@/store/mutations.type';

import PlanningArea from '@/components/PlanningArea.vue';
import TheLeafletRouteMap from '@/components/TheLeafletRouteMap.vue';
import ThePlanningInfoModal from '@/components/ThePlanningInfoModal.vue';
import ThePartitionSummary from '@/components/ThePartitionSummary.vue';
import TheDistributionModal from '@/components/TheDistributionModal.vue';

import SharedService from '@/services/shared.service';

import TaskTable from '@/components/table/TaskTable.vue';
import { GET_FILTERED_INSTANCES } from '@/store/actions.type';

export default {
  name: 'SharedMapView',
  components: {
    PlanningArea,
    TheLeafletRouteMap,
    ThePlanningInfoModal,
    ThePartitionSummary,
    TheDistributionModal,
    TaskTable,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showInfoModal: false,
      taskCount: 0,
      totalQuantity: 0,
      payload: {},
      workspaceName: '...',
      vehicleOptions: [],
      frequencyOptions: [],
      sharedService: null,
      isLoading: false,
      drivingListInfo: null,
      isGridActive: false,
      hasUpdated: false,
      taskIdList: [],
      gridFocusTrap: null,
      mapFocusTrap: null,
      mapFocusTrapActive: true,
    };
  },
  computed: {
    ...mapGetters('planning', ['partitionSummary', 'plannedInstancesLoading']),
  },
  created() {
    this.sharedService = SharedService.init(this.token);
  },
  async mounted() {
    document.querySelector('body').classList.remove('overflow-y-auto');
    document.addEventListener('keydown', this.closeTaskTableHotkey);

    this.isLoading = true;

    await this.loadWorkspaceDetails();
    await this.loadFilters();
    await this.fetchDrivingListsInfo();

    this.isLoading = false;

    this.$once('hook:beforeDestroy', () => {
      document.querySelector('body').classList.add('overflow-y-auto');
      document.removeEventListener('keydown', this.closeTaskTableHotkey);
      document.querySelector('main').classList.add('overflow-y-auto');
    });
  },
  methods: {
    async getPlannedInstanceDetails(plannedIdList) {
      return this.sharedService.getTaskDetails({ tasks: plannedIdList });
    },
    ...mapMutations('frequencies', {
      setMostIrregularFrequency: SET_MOST_IRREGULAR_FREQUENCY,
    }),
    ...mapMutations('planning', {
      setPlannedInstanceLoading: SET_PLANNED_INSTANCE_LOADING,
      setPartitionSummary: SET_PARTITION_SUMMARY,
      setDistributionModalOpen: SET_DISTRIBUTION_MODAL_OPEN,
    }),
    async loadFilters() {
      try {
        const { data } = await this.sharedService.getFilters();
        if (!data) {
          return;
        }

        this.vehicleOptions = data.vehicles;
        const { data: frequencyData } = await this.sharedService.getFrequenciesInUseAsSelectOptions();
        this.frequencyOptions = frequencyData;
        this.setMostIrregularFrequency(data.most_irregular_frequency);
      } catch (error) {
        this.$unhandledError(error, false);
      }
    },
    async loadWorkspaceDetails() {
      try {
        const { data } = await this.sharedService.getWorkspaceDetails();
        this.workspaceName = data.name;
      } catch (error) {
        this.$unhandledError(error, false);
      }
    },
    async downloadDrivingLists() {
      if (!this.drivingListInfo) {
        this.$ntf.warning('Filen blev ikke fundet');
        return;
      }

      this.$ntf.info('Henter kørelister');
      await this.sharedService.downloadDrivingLists(this.drivingListInfo.id);
    },
    async fetchDrivingListsInfo() {
      try {
        const { data } = await this.sharedService.getDrivingListInfo();
        this.drivingListInfo = data;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    onInfoModalClick() {
      this.showInfoModal = true;
    },
    async closeTaskTableHotkey(e) {
      if (!this.isGridActive) {
        return;
      }

      // esc key
      if (e.keyCode === 27) {
        this.closeTaskTable();
      }
    },
    async closeTaskTable() {
      if (!this.isGridActive) {
        return;
      }

      setTimeout(() => {
        this.isGridActive = false;
        this.$refs.mapContainer.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }, 500);

      if (this.hasUpdated) {
        // reload the instances to the backend
        this.$ntf.info('Opdaterer kort med ændringer..');
        try {
          this.$refs.map.clearFeatures(true);
          const plannedInstances = await this.getFilteredInstances(this.payload);
          if (this.$refs.map) {
            this.$refs.map.addFeatures(plannedInstances, false);
            this.$refs.map.focusLastEditedLayer();
          }
        } catch (error) {
          this.$ntf.error('Noget gik galt da ruterne skulle hentes');
          this.$unhandledError(error, false);
        }
      } else {
        this.$refs.map.focusLastEditedLayer();
      }
    },
    ...mapActions('planning', {
      getFilteredInstances: GET_FILTERED_INSTANCES,
    }),
    openGrid({ taskIdList, count, totalQuantity }) {
      this.isGridActive = true;
      this.hasUpdated = false;
      this.taskCount = count;
      this.totalQuantity = totalQuantity;
      this.taskIdList = taskIdList;
      this.$refs.gridContainer.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    },
    onInfoModalClose() {
      this.$refs.map.focus();
    },
    async onFilter(payload) {
      this.payload = payload;
      this.$refs.map.clearFeatures();
      try {
        this.setPlannedInstanceLoading(true);
        const { data } = await this.sharedService.filter(payload);
        this.setPartitionSummary(data.partition_summary);
        if (this.$refs.map) {
          this.$refs.map.addFeatures(data.planned_instances);
        }
      } catch (error) {
        this.$ntf.error('Noget gik galt da ruterne skulle hentes');
        this.$unhandledError(error, false);
      } finally {
        this.setPlannedInstanceLoading(false);
      }
    },
  },
};
</script>

<style>
.map-container-height {
  height: calc(100vh - 125px);
  max-height: calc(100vh - 125px);
}
.screen-h-topbar {
  height: calc(100vh - 48px);
}
</style>
